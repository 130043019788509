import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getFinancialYears: createCancelTokenHandler('getFinancialYears')
}

export function getFinancialYears (page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/settings/financial-years${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getFinancialYears'].handleRequestCancellation().token
  })
}

export function insertFinancialYear () {

  return axios({
    url: 'v1/admin/settings/financial-years',
    method: 'post'
  })
}

export function switchFinancialYear (id) {

  return axios({
    url: `v1/admin/settings/financial-years/${id}/switch`,
    method: 'post'
  })
}
