<template>
  <div class="financial-year-list" :class="{'fixed-draggable-dynamic-table-wrapper-height mx-auto': !inModal}">
    <draggable-dynamic-table ref="financialYearListTable"
                             :columns="columnsLabel"
                             :options="options"
                             :in-modal="inModal"
                             @show="handleShowFinancialYearClicked"
                             @filter:remove="setFilters($event)"
                             @filter:set="setFilters($event)"
                             @sort:set="setSort($event)"
                             @load:more="getFinancialYears()"
                             v-model="data"/>


    <!-- insert new user prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="editFinancialYearPromptStatus"
      @close="editFinancialYearPromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div @click="handleClick('insertFinancialYearBTN')">
              <custom-icon icon="SAVE" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('setting.financialYear.edit.title') }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="editFinancialYearPromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content p-2">
        <template>
          <keep-alive>
            <edit-financial-year :in-modal="true" @insert="editFinancialYearPromptStatus = false"/>
          </keep-alive>
        </template>
        <!--<cash-boxes-opening-balance v-if="header.suggestPromptComponent === 'cashBoxes'"/>-->
      </div>

    </vs-prompt>

    <button id="editFinancialYearBtn" v-show="false" @click="editFinancialYearPromptStatus = true"></button>

    <custom-dialog ref="switchFinancialYear"
                   :title="$t('setting.financialYear.confirmations.show.title')"
                   :body="$t('setting.financialYear.confirmations.show.body', {name: selectedFinancialYear ? selectedFinancialYear.name : ''})"
                   @accept="switchFinancialYear(selectedFinancialYear)"/>


    <button id="insertNewFinancialYearBtn" v-show="false" @click="$refs.insertFinancialYear.showDialog()"></button>
    <custom-dialog ref="insertFinancialYear"
                   :title="$t('setting.financialYear.confirmations.insert.title')"
                   :body="$t('setting.financialYear.confirmations.insert.body')"
                   @accept="insertFinancialYear()"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomDialog from '@/components/customDialog/customDialog'
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import EditFinancialYear from "../edit/editFinancialYear";
  import {
    getFinancialYears,
    insertFinancialYear,
    switchFinancialYear
  } from "../../../../../http/requests/settings/financialYear";
  import {checkUserPermissions} from "../../../../../assets/js/functions";
  import {getProfile} from "../../../../../http/requests/users/users";

  export default {
    name: 'financialYearList',
    components: {EditFinancialYear, CustomIcon, CustomDialog},
    metaInfo () {
      return {
        title: this.$t('setting.financialYear.title')
      }
    },
    props: {
      inModal: {
        type: Boolean,
        default: () => { return false }
      }
    },
    data () {
      return {
        editFinancialYearPromptStatus: false,
        options: {
          id: 'financialYearListTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'show',
            i18n: 'setting.financialYear.table.header.show',
            width: '80',
            minWidth: 80,
            align: 'center',
            actions: true,
            showAction: 'always',
            action: {
              color: 'primary',
              icon: 'EYE',
              iconPack: 'useral',
              type: 'button'
            },
            event: 'show'
          },
          {
            field: 'status',
            i18n: 'setting.financialYear.table.header.status',
            align: 'center',
            width: 'calc(100% / 10)',
            minWidth: 120,
            sortable: true,
            filter: true,
            filterType: 'select',
            filterTypes: [
              {
                label: this.$t('setting.financialYear.statusTypes.all'),
                value: -1
              },
              {
                label: this.$t('setting.financialYear.statusTypes.active'),
                value: 1
              },
              {
                label: this.$t('setting.financialYear.statusTypes.disActive'),
                value: 0
              }
            ]
          },
          {
            field: 'endDate',
            i18n: 'setting.financialYear.table.header.endDate',
            width: '50%',
            minWidth: 120,
            align: 'center',
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
            filterTypes: [
              {
                icon: 'calendar',
                name: 'تقویم',
                i18n: '',
                id: 1
              }
            ]
          },
          {
            field: 'startDate',
            i18n: 'setting.financialYear.table.header.startDate',
            width: '50%',
            minWidth: 120,
            align: 'center',
            sortable: true,
            filter: true,
            filterType: 'date',
            filterRange: true,
            filterTypes: [
              {
                icon: 'calendar',
                name: 'تقویم',
                i18n: '',
                id: 1
              }
            ]
          },
          {
            field: 'name',
            i18n: 'setting.financialYear.table.header.name',
            width: 'calc((100% / 10))',
            minWidth: 120,
            align: 'center',
            sortable: true,
            filter: true,
            filterTypes: [
              {
                icon: 'search',
                name: 'شامل شود',
                i18n: 'draggableTable.filter.types.search',
                id: 1
              }
            ]
          },
          {
            field: 'rowNumber',
            i18n: 'setting.financialYear.table.header.rowNumber',
            // sortable: true,
            // locked: true,
            align: 'center',
            width: '70px',
            minWidth: 70,
            footer: {}
          }
        ],
        statusOptions: [
          {
            label: this.$t('setting.financialYear.statusTypes.active'),
            value: 1
          },
          {
            label: this.$t('setting.financialYear.statusTypes.disActive'),
            value: 2
          }
        ],
        data: [],
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        page: 1,
        endedList: false,
        selectedFinancialYear: null,
        actions: {
          toolbar: [
            {
              id: 'insertNewFinancialYearBtn',
              icon: 'PLUS',
              iconPack: 'useral',
              color: 'success',
              permission: 'financial_year.create'
            }
          ],
          leftToolbar: [
            {
              id: 'editFinancialYearBtn',
              icon: 'SETTING',
              iconPack: 'useral',
              permission: 'financial_year.create'
            },
            // {
            //   id: 'settingTable',
            //   icon: 'icon-settings',
            //   iconPack: 'feather'
            // },
            {
              id: {name: 'Settings'},
              type: 'link',
              icon: 'CHEVRON_LEFT',
              iconPack: 'useral'
            }
          ]
        }
      }
    },
    created () {
      if (this.$route.name === 'Settings') {
        this.actions.leftToolbar.splice(1, 1)
      }
      if (!this.inModal || this.$route.name === 'Settings') {
        this.$nextTick(() => {
          this.$store.dispatch('auth/setAccessToken')
          this.$store.dispatch('updateNavbarActions', this.actions)
          this.$store.dispatch('updateContentNavbarStyle', 'sticky')
          this.$store.dispatch('updateContentNavbarClass', ' mx-auto')
        })
        this.$store.dispatch('setPageTitle', this.$t('setting.financialYear.title'))
      }

      if (!checkUserPermissions('financial_year.switch')) {
        const showIndex = this.columnsLabel.map(e => e.field).indexOf('show')
        if (showIndex > -1) {
          this.columnsLabel.splice(showIndex, 1)
        }
      }

      this.getFinancialYears()
    },
    methods: {
      checkUserPermissions (permission) {
        return checkUserPermissions(permission)
      },

      getFinancialYears () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.financialYearListTable && this.data.length === 0) this.$refs.financialYearListTable.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.financialYearListTable && this.data.length > 0) this.$refs.financialYearListTable.loadMoreStatus = 'Loading'

            getFinancialYears(this.page, this.filters, this.sorts).then((response) => {
              const financialYears = response.data

              financialYears.data.forEach((financialYear) => {
                this.data.push({
                  id: financialYear.id,
                  rowNumber: this.data.length + 1,
                  name: financialYear.name,
                  startDate: financialYear.started_at.split(' ')[0],
                  endDate: financialYear.finished_at.split(' ')[0],
                  active: financialYear.active,
                  selected: financialYear.selected,
                  status: {
                    value: financialYear.active ? this.statusOptions[0].label : this.statusOptions[1].label,
                    classes: financialYear.active ? 'useral-text-success' : 'useral-text-danger'
                  },
                  styles: financialYear.selected ? 'background: #f0f0f0;' : ''
                })
              })
              this.total_count = financialYears.pagination.total
              this.page = financialYears.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.financialYearListTable) this.$refs.financialYearListTable.loadMoreStatus = ''
            }).catch(error => {
              if (this.$refs.financialYearListTable && !axios.isCancel(error)) this.$refs.financialYearListTable.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilters (filters) {
        const filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {

            case 'name':
              if (filters[key].search !== '') filters_list.name = filters[key].search
              break

            case 'startDate':
              if (filters[key].search !== '') filters_list.started_at = `${Array.isArray(filters[key].search) ? filters[key].search.join('_') : filters[key].search}}`
              break

            case 'endDate':
              if (filters[key].search !== '') filters_list.finished_at = `${Array.isArray(filters[key].search) ? filters[key].search.join('_') : filters[key].search}}`
              break

            case 'status':
              if (filters[key].search.value > -1) filters_list.active = filters[key].search.value
              break
          }
        })

        this.data = []
        this.page = 1
        this.endedList = false
        this.filters = filters_list
        this.getFinancialYears()
      },
      setSort (columns) {
        const sorts_list = []
        Object.keys(columns).forEach((key) => {
          switch (key) {

            case 'row':
              sorts_list.push(`order[0]=id,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'name':
              sorts_list.push(`order[0]=name,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'startDate':
              sorts_list.push(`order[0]=started_at,${  columns[key] ? 'desc' : 'asc'}`)
              break

            case 'endDate':
              sorts_list.push(`order[0]=finished_at,${  columns[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (!sorts_list.length) sorts_list.push('order[0]=created_at,desc')

        this.data = []
        this.page = 1
        this.endedList = false
        this.sorts = sorts_list
        this.getFinancialYears()
      },
      switchFinancialYear (financialYear) {
        if (!financialYear.selected) {
          switchFinancialYear(financialYear.id).then(() => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('setting.financialYear.notifications.switch.success'),
              color: 'success',
              time: 2400,
              icon: 'icon-check',
              iconPack: 'feather'
            })
            // this.data = []
            // this.page = 1
            // this.endedList = false
            // this.getFinancialYears()
            this.handleFinancialYearSwitched()
          }).catch(error => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              this.$vs.notify({
                title: this.$t('alert.error.title'),
                text: this.$t('setting.financialYear.notifications.switch.error'),
                color: 'danger',
                time: 2400,
                icon: 'icon-alert-circle',
                iconPack: 'feather'
              })
            }
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.financialYear.notifications.isActived'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      },

      insertFinancialYear () {
        insertFinancialYear().then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('setting.financialYear.notifications.insert.success'),
            color: 'success',
            time: 2400,
            icon: 'icon-check',
            iconPack: 'feather'
          })
          this.data = []
          this.page = 1
          this.endedList = false
          this.getFinancialYears()
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            switch (error.response.status) {
              case 422:
                const error_mapper = {
                  'payment_gateways_balance_is_not_empty': this.$t('setting.financialYear.notifications.parseError.paymentGateways'),
                  'some_storeroom_receipt_was_disapproved': this.$t('setting.financialYear.notifications.parseError.storeroom'),
                  'the_financial_year_has_not_expired': this.$t('setting.financialYear.notifications.parseError.periodNotEnded')
                }
                error.response.data.data.errors.forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('setting.financialYear.notifications.insert.error'),
                  color: 'danger',
                  time: 2400,
                  icon: 'icon-alert-circle',
                  iconPack: 'feather'
                })
                break
            }
          }
        })
      },

      handleShowFinancialYearClicked (financialYear) {
        if (!financialYear.selected) {
          this.selectedFinancialYear = financialYear
          this.$refs.switchFinancialYear.showDialog()
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('setting.financialYear.notifications.isActived'),
            color: 'danger',
            time: 2400,
            icon: 'icon-alert-circle',
            iconPack: 'feather'
          })
        }
      },

      handleFinancialYearSwitched () {
        getProfile().then((response) => {
          const user = response.data.data
          const userInfo = {
            permissions: user.permissions,
            financialYear: user.financial_year
          }
          localStorage.setItem('userInfo', JSON.stringify(userInfo))
          location.reload()
        })
      },

      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .financial-year-list:not(.fixed-draggable-dynamic-table-wrapper-height) {
    height: 100%;
  }
</style>
