<template>
  <div class="useral-insert-financial-year">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <vs-row>
          <vs-col>
            <div>
              <u-radio-input
                  id="periodRadio"
                  :label="$t('setting.financialYear.edit.labels.period')"
                  :options="periodTypes"
                  v-model="financialYear.period"
              />
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertFinancialYearBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DatePicker from 'vue-persian-datetime-picker'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
  import CustomDialog from '../../../../../components/customDialog/customDialog'
  import {checkUserPermissions} from '../../../../../assets/js/functions'
  import CustomSelect from '../../../../../components/customSelect/customSelect'
  import CustomDatePickerInput from "../../../../../components/customInput/customDatePickerInput";
  import {insertFinancialYear} from "../../../../../http/requests/settings/financialYear";
  import {editAppSetting, getSetting} from "../../../../../http/requests/settings/setting";
  import URadioInput from "@/components/customInput/URadioInput.vue";

  export default {
    name: 'insertFinancialYear',
    components: {
      URadioInput,
      CustomSelect,
      CustomDatePickerInput,
      CustomDialog,
      CustomValidateInput
    },
    props: {
      financialYearId: 0
    },
    data() {
      return {
        periodTypes: [
          {
            label: this.$t('setting.financialYear.edit.periodTypes.3'),
            value: 3
          },
          {
            label: this.$t('setting.financialYear.edit.periodTypes.6'),
            value: 6
          },
          {
            label: this.$t('setting.financialYear.edit.periodTypes.12'),
            value: 12
          }
        ],
        financialYear: {
          period: {
            label: '-',
            value: 0
          }
        }
      }
    },
    created() {
      this.getFinancialYearSetting()
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      getFinancialYearSetting() {
        getSetting().then(response => {
          const app = response.data.data.app
          this.financialYear.period.value = app.financial_year_period
        })
      },
      sendData() {
        const payload = {
          financial_year_period: parseInt(this.financialYear.period.value),
        }

        editAppSetting(payload).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('setting.financialYear.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'financial_year_period': this.$t('setting.financialYear.notifications.parseError.period'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('setting.financialYear.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-financial-year {
    width: 100%;
    /*height: calc(100% - 10px);*/
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      border: 1px solid #cecece;
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;


      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        height: calc(100% - 230px);
      }

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .custom-profile-image-input {
          label {
            height: 110px;
            width: 110px;
          }
        }

        .time-picker {
          display: flex;
          justify-content: space-between;

          .date-picker-item {
            border: 0 !important;
            border-radius: 0.4rem;
            position: relative;
            flex-grow: 0.5;
            max-width: 48%;

            .custom-input {
              margin: 0;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        .input-field-item {
          position: relative;
          border: 1px solid #cdcdcd;
          border-radius: 0.4rem;
          padding: 5px 10px;
          margin: 15px 0;
          display: flex;
          justify-content: space-around;
          min-height: 35px;

          &.invalid {
            border-color: #b00000;

            .input-field-label {
              color: #b00000;
            }
          }

          .input-field-label {
            position: absolute;
            font-size: 12px;
            top: -10px;
            left: 8px;
            background-color: #ffffff;
            padding: 0 5px;
          }

          .radio-item {
            display: flex;
            align-items: center;
            direction: rtl;

            label {
              margin-left: 5px;
              direction: ltr;
            }
          }
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
